import React, { Component } from 'react'
import $ from 'jquery'
import FrontFooter from './common/Front-Footer'
import FrontWrapper from './common/FrontWrapper'
import LazyImage from '../common/lazyImage';

/* eslint jsx-a11y/anchor-is-valid: 0 */

class OurStory extends Component {
    componentDidMount() {
        const Isotope = require("isotope-layout");
        // require('../../buro-workers.min.js')\

        // $(document).ready(function () {
        // refer this link:  https://isotope.metafizzy.co/extras.html#webpack and https://github.com/metafizzy/isotope/issues/1450
        var $grid = new Isotope(".grid", {
            itemSelector: ".element-item",
            layoutMode: "fitRows"
        });

        $(".filter-options").on("click", "a", function () {
            var filterValue = $(this).attr("data-filter");
            // use filterFn if matches value
            $grid.isotope({ filter: filterValue });
            $(".filter-options a").removeClass("active");
            $(this).addClass("active");
        });
        // });

        // $(document).ready(function () {
        $("#loginform").click(function () {
            $(".login").fadeToggle("slow");
            $(".body-overlay").fadeToggle("slow");
            //$(this).toggleClass('green');
        });


        $(document).mouseup(function (e) {
            var container = $(".login");
            var overlay = $(".body-overlay");

            if (
                !container.is(e.target) &&
                container.has(e.target).length === 0 &&
                overlay.is(e.target)
            ) {
                overlay.fadeToggle("slow");
                container.fadeToggle("slow");
                //  $('#loginform').removeClass('green');
            }
        });
        // });

        var scrollSpeed = 10;
        var current = 0;
        var direction = "h";

        function bgscroll() {
            current -= 1;

            $(".video-image-wrapper").css(
                "backgroundPosition",
                direction === "h" ? current + "px 44%" : "0 " + current + "px"
            );
        }

        setInterval(bgscroll, scrollSpeed);
    }

    render() {
        const sImg = require("../../../static/assets/img/static/beacons/savji.png");
        const gImg = require("../../../static/assets/img/static/beacons/ghanshyam.png");
        const hImg = require("../../../static/assets/img/static/beacons/himmat.png");
        const tImg = require("../../../static/assets/img/static/beacons/tulsi.png");

        return (
            <FrontWrapper location={this.props.location}>
                <div className="scroll-content-wrapper" data-scrollbar>
                    <div className="page-main page-current">
                        <div
                            className="page-toload legacy-page"
                            data-bodyclassname="legacy"
                        >
                            <main className="page-content" role="main">
                                <section className="section-container our-story pb-0">
                                    <h4 className="show-text sub-head padd-max js-type-animation-alt">
                                        Our Story
                                    </h4>
                                    <h2 className="show-text head padd-max js-type-animation-alt">
                                        Faith. Honesty. Transparency.
                                    </h2>
                                    <p className="mt-30">
                                        The three corner stones upon which the Hari Krishna Group
                                        has thrived for over two decades, outshining in terms of
                                        quality and service standards in the Diamond industry.
                                    </p>
                                    <div className="values-wrap mt-30 d-flex d-block-xs">
                                        <div className="values d-flex align-items-center">
                                            <p>
                                                We are the only Indian company with 6000+ employees to
                                                have achieved the Responsible Jewellery Council
                                                Certification.
                                            </p>
                                        </div>
                                        <div className="values d-flex align-items-center">
                                            <p>
                                                We have been honoured by GJEPC with 16 Awards for 14
                                                consecutive years.
                                            </p>
                                        </div>
                                        <div className="values d-flex align-items-center">
                                            <p>
                                                Our presence commands trust and confidence in every
                                                sphere of activity.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="our-partners">
                                        <h2 className="show-text head padd-max js-type-animation-alt partner-head">
                                            Our partners
                                        </h2>
                                        <div className="partners-wrap mt-30 d-flex align-items-center d-flex-wrap">
                                            <a href="http://gss.debeersgroup.com/customer-directory/hari-krishna-exports-pvt-ltd"
                                                className="partner d-flex align-items-center j-center">
                                                <LazyImage
                                                    src={require("../../../static/assets/svg/index/dtc.png")}
                                                    alt=""
                                                />
                                            </a>
                                            <a href="http://www.riotinto.com/"
                                                className="partner d-flex align-items-center j-center">
                                                <LazyImage
                                                    src={require("../../../static/assets/svg/index/riotinto-logo.svg")}
                                                    alt=""
                                                />
                                            </a>
                                            <a href="https://www.ddmines.com/"
                                                className="partner d-flex align-items-center j-center">
                                                <LazyImage
                                                    src={require("../../../static/assets/svg/index/dominion.svg")}
                                                    alt=""
                                                />
                                            </a>
                                            <a href="http://www.canadamark.com/"
                                                className="partner d-flex align-items-center j-center">
                                                <LazyImage
                                                    src={require("../../../static/assets/svg/index/canada-mark.svg")}
                                                    alt=""
                                                />
                                            </a>
                                            <a href="http://sales.alrosa.info/"
                                                className="partner d-flex align-items-center j-center">
                                                <LazyImage
                                                    src={require("../../../static/assets/svg/index/alrosa.png")}
                                                    alt=""
                                                />
                                            </a>
                                            <a href="http://www.forevermark.com/"
                                                className="partner d-flex align-items-center j-center">
                                                <LazyImage
                                                    src={require("../../../static/assets/svg/index/forevermark.svg")}
                                                    alt=""
                                                />
                                            </a>
                                            <a href="http://www.responsiblejewellery.com/member/hari-krishna-exports/"
                                                className="partner d-flex align-items-center j-center">
                                                <LazyImage
                                                    src={require("../../../static/assets/svg/index/jeweller-counsil.svg")}
                                                    alt=""
                                                />
                                            </a>
                                            <a href="https://www.worlddiamondcouncil.org/"
                                                className="partner d-flex align-items-center j-center">
                                                <LazyImage
                                                    src={require('../../../static/assets/img/diamond-counsil-logo.png')}
                                                    alt=""
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="mt-20" style={{ textAlign: "-webkit-center" }}>
                                        <img
                                            src={require("../../../static/assets/svg/index/down-arrow.svg")}
                                            alt=""
                                        />
                                    </div>
                                </section>
                                {/* <section className="achievement-wrap">
                  <div className="section-container m-lr-auto pt-0 pb-0">
                    <h2 className="show-text head padd-max js-type-animation-alt">
                      Achievements
                    </h2>
                    <div className="filter-options">
                      <a className="filter active" data-filter="*">
                        All
                      </a>
                      <a className="filter" data-filter=".a2002">
                        2002
                      </a>
                      <a className="filter" data-filter=".a2003">
                        2003
                      </a>
                      <a className="filter" data-filter=".a2004">
                        2004
                      </a>
                      <a className="filter" data-filter=".a2005">
                        2005
                      </a>
                      <a className="filter" data-filter=".a2006">
                        2006
                      </a>
                      <a className="filter" data-filter=".a2007">
                        2007
                      </a>
                      <a className="filter" data-filter=".a2008">
                        2008
                      </a>
                      <a className="filter" data-filter=".a2009">
                        2009
                      </a>
                      <a className="filter" data-filter=".a2010">
                        2010
                      </a>
                      <a className="filter" data-filter=".a2011">
                        2011
                      </a>
                      <a className="filter" data-filter=".a2012">
                        2012
                      </a>
                      <a className="filter" data-filter=".a2013">
                        2013
                      </a>
                      <a className="filter" data-filter=".a2014">
                        2014
                      </a>
                    </div>
                    <div className="grid d-flex d-flex-wrap">
                      <div className="element-item achievement a2002">
                        <img
                          src={require("../../assets/img/static/achievements/1.png")}
                          alt=""
                        />
                        <div className="ach-cont">
                          <h4>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </h4>
                          <h5>August 23 2017</h5>
                          <p>
                            Mr. Parag Shah & Mr. Dravya Dholakia receiving the
                            award from Hon'ble Cheif Minister Of Maharashtra Mr.
                            Devendra Fadnavis.
                          </p>
                        </div>
                      </div>
                      <div className="element-item achievement a2003">
                        <img
                          src={require("../../assets/img/static/achievements/2.png")}
                          alt=""
                        />
                        <div className="ach-cont">
                          <h4>Lorem Ipsum is simply dummy text of the.</h4>
                          <h5>August 23 2011</h5>
                          <p>
                            Mr. Savji Dholakia receiving the GJEPC's Annual
                            Export Award from the Hon. Chief Minister of
                            Gujarat. Mr. Narendra Modi for the year 2010-11
                          </p>
                        </div>
                      </div>
                      <div className="element-item achievement a2004">
                        <img
                          src={require("../../assets/img/static/achievements/3.png")}
                          alt=""
                        />
                        <div className="ach-cont">
                          <h4>Lorem Ipsum is simply dummy text of the.</h4>
                          <h5>August 23 2017</h5>
                          <p>
                            Mr. Pintu Dholakia receiving the GJEPC Export award
                            for the year 2008-09, from Hon'able Union Minister
                            of Commerce & Industry, Mr. Anand Sharma
                          </p>
                        </div>
                      </div>
                      <div className="element-item achievement a2005">
                        <img
                          src={require("../../assets/img/static/achievements/4.png")}
                          alt=""
                        />
                        <div className="ach-cont">
                          <h4>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </h4>
                          <h5>August 23 2017</h5>
                          <p>
                            Mr. Parag Shah & Mr. Dravya Dholakia receiving the
                            award from Hon'ble Cheif Minister Of Maharashtra Mr.
                            Devendra Fadnavis.
                          </p>
                        </div>
                      </div>
                      <div className="element-item achievement a2006">
                        <img
                          src={require("../../assets/img/static/achievements/5.png")}
                          alt=""
                        />
                        <div className="ach-cont">
                          <h4>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </h4>
                          <h5>August 23 2017</h5>
                          <p>
                            Mr. Parag Shah & Mr. Dravya Dholakia receiving the
                            award from Hon'ble Cheif Minister Of Maharashtra Mr.
                            Devendra Fadnavis.
                          </p>
                        </div>
                      </div>
                      <div className="element-item achievement a2007">
                        <img
                          src={require("../../assets/img/static/achievements/6.png")}
                          alt=""
                        />
                        <div className="ach-cont">
                          <h4>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </h4>
                          <h5>August 23 2017</h5>
                          <p>
                            Mr. Parag Shah & Mr. Dravya Dholakia receiving the
                            award from Hon'ble Cheif Minister Of Maharashtra Mr.
                            Devendra Fadnavis.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>*/}
                                <section className="section-container our-values mb-25">
                                    <div className="hidden-sm-up">
                                        <h2 className="show-text head padd-max js-type-animation-alt mb-50 mb-xs-20">
                                            Our Values
                                        </h2>
                                        <div className="our-values-wrap d-flex d-xs-block flex-wrap">
                                            <div
                                                className="our-values-content mb-20"
                                                style={{ textAlign: "-webkit-center" }}
                                            >
                                                <img
                                                    src={require("../../../static/assets/svg/index/value-1.svg")}
                                                    alt=""
                                                />
                                                <h3>Faith</h3>
                                                <p>
                                                    We will always approach every decision and action with absolute and
                                                    unwavering faith. Infused with faith, our work environment, processes
                                                    and output will be aimed at building abiding trust amongst customers,
                                                    employees, bankers, suppliers, well-wishers and all belonging to our
                                                    larger ecosystem.
                                            </p>
                                            </div>
                                            <div
                                                className="our-values-content mb-20"
                                                style={{ textAlign: "-webkit-center" }}
                                            >
                                                <img
                                                    src={require("../../../static/assets/svg/index/value-2.svg")}
                                                    alt=""
                                                />
                                                <h3>Aptitude </h3>
                                                <p>
                                                    At HK, we create an environment that promotes aptitude for work and
                                                    creates a natural inclination towards devotion to one’s duty. We believe
                                                    that aptitude for excellence in work is not a gift but a result of the
                                                    positive energy and approach towards work that is inculcated and
                                                    sustained through right practices and belief systems.
                                            </p>
                                            </div>
                                            <div
                                                className="our-values-content mb-20"
                                                style={{ textAlign: "-webkit-center" }}
                                            >
                                                <img
                                                    src={require("../../../static/assets/svg/index/value-3.svg")}
                                                    alt=""
                                                />
                                                <h3>
                                                    Innovation</h3>
                                                <p>
                                                    Our work culture enables each an every one of us to work with freedom
                                                    and ownership. We believe this approach fosters innovation and helps us
                                                    add value to our offerings. We believe our growth lies in empowering our
                                                    people to come foward and bring their unique perspectives to the table.
                                            </p>
                                            </div>
                                            <div
                                                className="our-values-content"
                                                style={{ textAlign: "-webkit-center" }}
                                            >
                                                <img
                                                    src={require("../../../static/assets/svg/index/value-4.svg")}
                                                    alt=""
                                                />
                                                <h3>Togetherness</h3>
                                                <p>
                                                    Across all levels and functions, we will always strive to work in an
                                                    environment of togetherness and equality. We take pride in creating an
                                                    environment where everyone is part of a family; not just employees but
                                                    their families form a part of this integrated culture. We’re driven as a
                                                    family with one common purpose to achieve the highest standards of
                                                    offerings, service and commitment.
                                            </p>
                                            </div>
                                            <div
                                                className="our-values-content"
                                                style={{ textAlign: "-webkit-center" }}
                                            >
                                                <img
                                                    src={require("../../../static/assets/svg/index/value-5.svg")}
                                                    alt=""
                                                />
                                                <h3>Hardwork</h3>
                                                <p>
                                                    At Hari Krishna, we believe persistent hardwork is the key to success in
                                                    all we do. Our dedication to working hard, every day, helps us cross
                                                    milestones consistently and enables us to keep setting higher standards
                                                    for ourselves. It has been our way of growth over these years.
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden-xs">
                                        <LazyImage className="value-desktop-view" src={require("../../../static/assets/svg/index/output-onlinepngtools-copy.svg")}
                                            alt="" />
                                    </div>
                                </section>
                                {/*<div className="section-container journy-countainer  story-journey-block">
                  <h2 className="show-text head padd-max js-type-animation-alt mb-50">
                    Our Journey
                  </h2>
                  <OurJourney />
                </div>*/}

                                <div className="section-container beacons-container pt-0">
                                    <h2 className="show-text head padd-max js-type-animation-alt">
                                        Beacons of Inspiration
                                    </h2>
                                    <p className="bea-desc mb-50 mb-xs-20">
                                        A group of self made men bestowed with the trust and beliefs
                                        of both clients and employees, who plan and implement
                                        strategies and policies that not just bring success but
                                        spread cheer;they are our beacons of inspiration.
                                    </p>

                                    <div className="beacons-wrap d-flex align-items-center d-block-xs">
                                        <figure style={{ backgroundImage: "url(" + sImg + ")" }} />
                                        <div className="beacon">
                                            <h4 className="title">Mr. Savji Dholakia</h4>
                                            <h5 className="desig">Founder</h5>
                                            <p className="info">
                                                A man of ideologies, an embodiment of hard work, Mr.
                                                Savji Dholakia’s journey from the lower rungs of diamond
                                                cutting to the upper echelons of the diamond industry,
                                                is one of devotion and painstaking dedication. A
                                                visionary with over 25 years of experience in rough
                                                diamond purchase, manufacturing and management, he has
                                                today risen above all as a humanitarian,committing a
                                                significant amount of his time to Social Welfare and
                                                Development.
                                            </p>
                                            <div className="beacon-social d-flex">
                                                <a className="d-flex align-items-center j-center"
                                                    href="https://www.facebook.com/DholakiaSavji" target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img
                                                        src={require("../../../static/assets/svg/index/facebook.svg")}
                                                        alt=""
                                                    />
                                                </a>
                                                <a className="d-flex align-items-center j-center"
                                                    href="http://www.twitter.com/SavjiDholakia" target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img
                                                        src={require("../../../static/assets/svg/index/twitter.svg")}
                                                        alt=""
                                                    />
                                                </a>
                                                <a className="d-flex align-items-center j-center"
                                                    href="http://in.linkedin.com/in/SavjiDholakia" target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img
                                                        src={require("../../../static/assets/svg/index/linkedin.svg")}
                                                        alt=""
                                                    />
                                                </a>
                                                <a className="d-flex align-items-center j-center"
                                                    href="https://en.wikipedia.org/wiki/Savji_Dholakia" target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img
                                                        src={require("../../../static/assets/svg/index/worldwide.svg")}
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="beacons-wrap d-flex align-items-center d-block-xs">
                                        <figure style={{ backgroundImage: "url(" + gImg + ")" }} />
                                        <div className="beacon">
                                            <h4 className="title">Mr. Ghanshyam Dholakia</h4>
                                            <h5 className="desig">Founder</h5>
                                            <p className="info">
                                                Optimist, the word that aptly describes Mr. Ghanshyam
                                                Dholakia; his firm grip on international trends and
                                                strong marketing skills has ensured the creation of
                                                newer markets, helping our Group to carve a niche for
                                                itself in the markets present: USA, Japan, Europe,
                                                Israel, UK and Hong Kong – besides India.
                                            </p>
                                            <div className="beacon-social d-flex">
                                                <a className="d-flex align-items-center j-center"
                                                    href="https://www.facebook.com/GDDholakiya" target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img
                                                        src={require("../../../static/assets/svg/index/facebook.svg")}
                                                        alt=""
                                                    />
                                                </a>
                                                <a className="d-flex align-items-center j-center"
                                                    href="http://in.linkedin.com/in/GDDholakia" target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img
                                                        src={require("../../../static/assets/svg/index/linkedin.svg")}
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="beacons-wrap d-flex align-items-center d-block-xs">
                                        <figure style={{ backgroundImage: "url(" + tImg + ")" }} />
                                        <div className="beacon">
                                            <h4 className="title">Mr. Tulsi Dholakia</h4>
                                            <h5 className="desig">Founder</h5>
                                            <p className="info">
                                                Efficiency, the word that defines Mr. Tulsi Dholakia;
                                                his precision in formulating an efficient manufacturing
                                                process that strictly adheres to a meticulous course of
                                                actionis uncanny. His competence stems from his
                                                ambidextrous flair for the machines and the people who
                                                operate them. He pioneered the active use of Sarin
                                                Planner in the company’sfactory and today, Hari Krishna
                                                Exports is a member of Sarin Century Club.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="beacons-wrap d-flex align-items-center d-block-xs">
                                        <figure style={{ backgroundImage: "url(" + hImg + ")" }} />
                                        <div className="beacon">
                                            <h4 className="title">Mr. Himmat Dholakia</h4>
                                            <h5 className="desig">Founder</h5>
                                            <p className="info">
                                                Intelligence and prudent use of technology are two
                                                extremely diverse things which when fused together bring
                                                about extraordinary results; our over 6000-strong
                                                manpower and the latest technologies when combined
                                                together functions like a well oiled machine delivering
                                                superior quality products andexcellent services. As Head
                                                of Diamond Manufacturing, Mr. Himmat Dholakia has
                                                brought about this dramatic change, an e-age marvel!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="vision-mission-wrap mb-50">
                  <div className="d-flex d-block-xs">
                    <div className="vision-mission d-flex align-items-center bg-vision">
                      <div className="v-content">
                        <h3>Our Vision</h3>
                        <p>
                          To be recognized as one of the foremost companies in
                          the diamond industry worldwide delivering value across
                          all stakeholders.
                        </p>
                      </div>
                    </div>
                    <div className="vision-mission d-flex align-items-center bg-misssion">
                      <div className="v-content">
                        <h3>Our Mission</h3>
                        <p>
                          To continuously grow and prosper in a well aligned
                          culture that's infused with positive energy. To
                          consistently strive at achieving greater excellence at
                          what and how we create.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>*/}

                                <div className="section-container business-principles pt-0">
                                    <h2 className="show-text head padd-max js-type-animation-alt mb-50 mb-xs-20">
                                        Business Principles
                                    </h2>
                                    <div className="principle-wrap d-flex d-flex-wrap">
                                        <div className="principle">
                                            <h4>Money Laundering, Terrorism Financing :</h4>
                                            <ul>
                                                <li>
                                                    Hari Krishna Group recognizes the fact that entities in the gems and
                                                    jewellery sector have to take on the onus of analysing their
                                                    potential vulnerabilities to money laundering and implement specific
                                                    steps that are required for protection against abuse by criminals.
                                                </li>
                                                <li>
                                                    Hari Krishna Group shall act in accordance with national laws with
                                                    respect to auditing of its financial accounts and maintaining
                                                    internal controls as guided by various regulations.
                                                </li>
                                                <li>
                                                    Compliance officer will ensure all the critical steps such as KYC&
                                                    KYS, Identification of suspicious transaction, reporting to
                                                    management and record keeping as required by the local act and
                                                    legislations are complied with.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="principle">
                                            <h4>Kimberley Process and System of Warranties :</h4>
                                            <ul>
                                                <li>
                                                    Hari Krishna Group is fully committed to complying with all the
                                                    requirements specified in the Kimberley Process Certification Scheme
                                                    and World Diamond Council’s (WDC) System of Warranties Declaration.
                                                </li>
                                                <li>
                                                    The definition of ‘Conflict Gem Stone Diamonds’ as agreed by the
                                                    Kimberley Process will be adopted i.e “Rough diamonds used by rebel
                                                    movements or their allies to finance conflict aimed at undermining
                                                    legitimate Governments, as described in relevant United Nations
                                                    Security Council (UNSC) resolutions in so far as they remain in
                                                    effect, or in other similar UNSC resolutions which may be adopted in
                                                    the future, and as understood and recognized in United Nations
                                                    General Assembly (UNGA) Resolution 55/56, or in other similar UNGA
                                                    resolutions which may be adopted in the future.”
                                                </li>
                                                <li>
                                                    Wherever applicable, the following affirmative statement as
                                                    recommended by the World Diamond Council’s System of Warranties
                                                    should be printed on all the invoices: Entering into transactions
                                                    involving ‘conflict diamonds’ or not following the System of
                                                    Warranties Declaration in invoices, either knowingly or unknowingly,
                                                    will be considered as a violation of the Business Principles.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="principle">
                                            <h4>Anti-Bribery and Facilitation Payment Policy :</h4>
                                            <ul>
                                                <li>
                                                    The Hari Krishna Group shall ensure complete prohibition Bribery and
                                                    facilitation payment across organization and in all the entities.
                                                </li>
                                                <li>
                                                    Company will not offer, accept or countenance any payment, gift in
                                                    kind, hospitality, expense or promises as such that may compromise
                                                    promises of fair competition.
                                                </li>
                                                <li>
                                                    Anti shall prohibit bribery and facilitation payment and shall
                                                    comply with various rules and regulations of the land.
                                                </li>
                                                <li>
                                                    Period training and awareness shall be carried out to educate
                                                    employees about various type and ways of bribery and facilitation
                                                    payments.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="principle">
                                            <h4>
                                                Disclosure of Treated Diamonds, Synthetics and Simulant :
                                            </h4>
                                            <ul>
                                                <span>The following essential principles will be applicable in all the transactions involving treated diamonds, synthetics and stimulant</span>
                                                <li>
                                                    Full disclosure i.e. the complete and total release of all available
                                                    information about a Diamond and all material steps it has undergone
                                                    prior to sale to the purchaser, irrespective of whether or not the
                                                    information is specifically requested and regardless of the effect
                                                    on the value of the diamond.
                                                </li>
                                                <li>
                                                    No misuse of terminology or mis-representations or attempts to
                                                    disguise the product will be made in the selling, advertising and
                                                    distribution of treated diamonds, synthetics and stimulant.
                                                </li>
                                                <li>
                                                    The word ‘diamond’ will not be used in the case of names of firms,
                                                    manufacturers or trademarks; in connection with treated diamonds or
                                                    diamond simulant or synthetic diamonds.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="principle">
                                            <h4>Gold Sourcing Policy :</h4>
                                            <ul>
                                                <li>
                                                    Our company is concerned about the environment and social impacts of
                                                    irresponsible mining.
                                                </li>
                                                <li>
                                                    We at Hari Krishna Group shall ensure that all our gold suppliers
                                                    compliance with gold souring guidelines.
                                                </li>
                                                <li>
                                                    Further we are committed to ensure that sourcing of gold and
                                                    precious metals products and articles are under the highest social,
                                                    human right and environmental standard caution of trade.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="principle">
                                            <h4>Supply Chain Management / Best Endeavors :</h4>
                                            <ul>
                                                <li>
                                                    The management of Hari Krishna Group is committed to taking
                                                    appropriate action to use best endeavors to ensure the commitment of
                                                    Tier 2 & Tier 3 entities to comply with the Best Practice Principles
                                                </li>
                                                <li>
                                                    Note: Where ever applicable necessary declaration of compliance is
                                                    obtained.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="principle">
                                            <h4>Employment :</h4>
                                            <ul>
                                                <li>
                                                    Compliance is required at all times, with applicable national and,
                                                    where appropriate, international laws / regulations with respect to
                                                    employment and labour.
                                                </li>
                                                <li>
                                                    The Company shall not require workers to work for more than the
                                                    national
                                                </li>
                                                <li>
                                                    The Company shall ensure that wages and benefits for a standard
                                                    working week shall meet at least national minimum standards and
                                                    shall be sufficient to meet the basic needs of workers and provide
                                                    some discretionary income.
                                                </li>
                                                <li>
                                                    It is the responsibility of concerned personnel to know and
                                                    understand the relevant employment and labour related legal,
                                                    regulatory and internal requirements as they apply to their jobs.
                                                </li>
                                                <li>
                                                    When required, due recognition will be given to the existence,
                                                    membership and lawful activities of worker representative bodies,
                                                    and worker representatives will be given access to carry out their
                                                    responsibilities / functions.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="principle">
                                            <h4>Health and Safety :</h4>
                                            <ul>
                                                <span>Hari Krishna Group recognizes the need to develop a sustainable, value creating business and is committed to the following:</span>
                                                <li>
                                                    Any adverse impact of our business processes on those who carry it
                                                    out shall be identified and eliminated. Towards this end, we will
                                                    systematically review our operations to identify sources of health
                                                    and safety related risks.
                                                </li>
                                                <li>
                                                    This review will use appropriate standards as required by prevailing
                                                    laws, expert opinion and our knowledge of best practices.
                                                </li>
                                                <li>
                                                    The review will lead to formulation of clearly described work
                                                    practices and drills.
                                                </li>
                                                <li>
                                                    All our staff will be trained in the manner required to adhere to
                                                    these work practices and drills.
                                                </li>
                                                <li>
                                                    The health of our staff, exposed to certain hazardous processes,
                                                    will be monitored periodically through appropriate medical checks,
                                                    and reviewed using expert inputs for improvements.
                                                </li>
                                                <li>
                                                    Workers shall not be under the influence of or abusing, drugs,
                                                    alcohol and/ or other illegal substances.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="principle">
                                            <h4>Non Discrimination, Disciplinary Practices :</h4>
                                            <ul>
                                                <li>
                                                    Discrimination can mean distinction, exclusion or preference.
                                                </li>
                                                <li>
                                                    Any form of discrimination relating to the hiring, discharge, pay,
                                                    promotion and training of employees on the basis of race, caste,
                                                    national origin, religion, age, disability, gender, marital status,
                                                    sexual orientation, HIV status, Migrant status, membership of worker
                                                    representative bodies, political affiliations, or any criteria that
                                                    are unlawful is strongly discouraged by the Company and any such
                                                    reported incidents will be viewed as a serious violation of this
                                                    Business Principles.
                                                </li>
                                                <li>
                                                    Hari Krishna Group will ensure that employees who have certain life
                                                    threatening diseases or illnesses are not treated differently from
                                                    other employees, and will continue to employ such personnel, as long
                                                    as they are physically and mentally fit to attend to their normal
                                                    job responsibilities.
                                                </li>
                                                <li>
                                                    Hari Krishna Group shall at no time condone the use of corporal
                                                    punishment or other forms of mental or physical coercion
                                                </li>
                                                <li>
                                                    Hari Krishna Group encourages all personnel to voice concerns
                                                    promptly, if they have a genuine reason to believe that a policy,
                                                    company operation or practice is or will likely be in violation of
                                                    any law, regulation or internal Company rule or policy, including
                                                    this Business Principles.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="principle">
                                            <h4>Forced Labour :</h4>
                                            <ul>
                                                <span>
                                                    The management of HARI KRISHNA GROUP is fully committed to ensuring that forced or involuntary labour is not practiced in any form at any of its facilities. Any reported incidents relating to forced labour will be considered as a serious violation of this Business Principles.
                        </span>
                                                <span>The following definitions will be applicable: </span>
                                                <li>
                                                    The Universal Declaration of Human Rights that states that ‘No one
                                                    shall be held in slavery or servitude’
                                                </li>
                                                <li>
                                                    ILO Convention 29, which defines forced or compulsory labour as ‘all
                                                    work or service which is extracted from any person under the menace
                                                    of any penalty, and for which the said person has not offered
                                                    himself voluntarily”
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="principle">
                                            <h4>Human Rights :</h4>
                                            <ul>
                                                <li>
                                                    All employees in the Company’s facilities will be treated with
                                                    equality, respect and dignity.
                                                </li>
                                                <li>
                                                    Hari Krishna Group will not interfere in the right of employees to
                                                    observe tenets or practices based on caste, race, national origin,
                                                    gender, religion, disability, union membership, or political
                                                    affiliation
                                                </li>
                                                <li>
                                                    The Company strongly discourages any form of sexually coercive,
                                                    threatening, abusive or exploitative behavior.
                                                </li>
                                                <li>
                                                    Any reported incidents relating to direct or indirect physical,
                                                    sexual, racial, religious, psychological, verbal, or any other form
                                                    of harassment or abuse, or any other form of intimidation or
                                                    degrading treatment will not be tolerated by the company.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="principle">
                                            <h4>Environment Protection :</h4>
                                            <ul>
                                                <span>
                                                    Hari Krishna Group is committed to effective environmental management as one of its important corporate priorities, and will focus on the following initiatives:
                        </span>
                                                <li>
                                                    Compliance with all applicable environmental laws and regulations
                                                </li>
                                                <li>
                                                    The impact of each of our operations on the environment will be
                                                    systematically assessed for compliance with appropriately defined
                                                    standards and reviewed periodically to mitigate or eliminate such
                                                    impact.
                                                </li>
                                                <li>
                                                    Disposal procedures for waste generated will be clearly defined and
                                                    practiced in line with standards that are set by law and best
                                                    practices of the industry.
                                                </li>
                                                <li>
                                                    Improvement of employee environmental awareness and performance
                                                    through detailed policies and procedures, training, and recognition
                                                    of excellence.
                                                </li>
                                                <li>
                                                    Measurement of environmental performance through auditing with
                                                    employee accountability and reporting to senior management.
                                                </li>
                                                <li>
                                                    Commitment to a continual improvement process in environmental
                                                    management
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="principle">
                                            <h4>Product Security :</h4>
                                            <ul>
                                                <li>
                                                    Hari Krishna Group is committed to provide safety of product
                                                    throughout its supply chain by following precaution as mentioned
                                                    below
                                                </li>
                                                <li>
                                                    Each and every stage of product processing it is covered through
                                                    blanket insurance
                                                </li>
                                                <li>
                                                    Suitable safe guarding and storage is ensured at all stage with the
                                                    help of safes
                                                </li>
                                                <li>
                                                    All the manufacturing, sales and retailing units are guarded by
                                                    security agency and monitored by close circuit cameras.
                                                </li>
                                                <li>
                                                    All the concern persons are trained on relevant safety and security
                                                    procedures to be followed at all time.
                                                </li>
                                                <li>
                                                    Organization has developed emergency plan, which includes procedure
                                                    in case of emergency (include emergency scenario such as theft
                                                    robbery etc.)
                                                </li>
                                                <li>
                                                    Product purity quality and other parameters are monitored at each
                                                    stage to avoid switch over of the product.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="principle">
                                            <h4>Synthetic Diamonds :</h4>
                                            <ul>
                                                <span>Following methodology has to be adopted for ensuring compliance to synthetic Diamonds.</span>
                                                <li>
                                                    Access to effective detection system
                                                </li>
                                                <li>
                                                    Buying from trusted suppliers
                                                </li>
                                                <li>
                                                    Factory controls in place and safety measure are to be implemented
                                                    to control switchover of diamonds.
                                                </li>
                                                <li>
                                                    Reporting of un-disclosure synthetics to supplier and interested
                                                    parties whenever detected.
                                                </li>
                                                <li>
                                                    Record the incidents of contamination reported and implement
                                                    suitable corrective and preventive measures for effective controls.
                                                </li>
                                                <li>
                                                    Tabulate the flow of business processes and identify the potential
                                                    areas of contaminations
                                                </li>
                                                <li>
                                                    Classify contamination of points into different category (High,
                                                    Medium & Low)
                                                </li>
                                                <li>
                                                    Identify the policy, procedure and test mechanism to implement test
                                                    mechanism in the organization.
                                                </li>
                                                <li>
                                                    Sampling size and criteria for different size and shape of diamonds
                                                    to be developed, implemented and records of testing to be
                                                    maintained.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/*<section className="philosophy relative mt-0 p-tb-250">
                  <div className="container relative">
                    <div className="page-heading text-center white-text mb-50">
                      Our Philosophy
                    </div>
                    <div className="slider-wrap">
                      <div className="slider">
                        <Slider {...settings}>
                          <div className="slide">
                            <h3 className="white-text">Faith Is In The Name</h3>
                            <p>
                              When your name is inspired from one of Hindu
                              mythology’s most revered God, it becomes
                              prerequisite that you elevate your business to
                              another level. We at Hari Krishna don’t see work
                              as simply day to day transactions.
                            </p>
                          </div>
                          <div className="slide">
                            <h3 className="white-text">Faith Is In The Name</h3>
                            <p>
                              When your name is inspired from one of Hindu
                              mythology’s most revered God, it becomes
                              prerequisite that you elevate your business to
                              another level. We at Hari Krishna don’t see work
                              as simply day to day transactions.
                            </p>
                          </div>
                          <div className="slide">
                            <h3 className="white-text">Faith Is In The Name</h3>
                            <p>
                              When your name is inspired from one of Hindu
                              mythology’s most revered God, it becomes
                              prerequisite that you elevate your business to
                              another level. We at Hari Krishna don’t see work
                              as simply day to day transactions.
                            </p>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </section>*/}
                                <FrontFooter />
                            </main>
                            <div className="preload" aria-hidden="true" />
                        </div>
                    </div>
                    <div className="page-main page-next" aria-hidden="true" />
                    <div className="page-main page-prev" aria-hidden="true" />
                    <footer className="footer" role="contentinfo" />
                </div>
            </FrontWrapper>
        );
    }
}

export default OurStory;
