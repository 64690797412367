import React from "react";
import OurStoryComponent from "../components/FrontStatic/OurStory";
import {graphql} from "gatsby";
import SEO from "../components/SEO";

const OurStory = (props) => <React.Fragment>
    <SEO {...(props?.data?.seo?.frontmatter || {})}/>
    <OurStoryComponent {...props} />
</React.Fragment>;

export default OurStory
export const query = graphql`
  query OurStoryQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "our-story-seo"}}) {
        frontmatter {
            title
            description
        }
    }
 }
`;
